<template>
  <div class="bg-white h-full p-4">
    <div class="topic-content" style="flex: 12">
      <div class="flex items-center justify-between">
        <div class="flex items-center gap-2">
          <img src="/images/avatar-default.svg" alt class="w-4 h-4" />
          <span
            class="font-medium name"
          >{{topicDetail.is_incognito === 2 ? 'Ẩn danh' : topicDetail.user && topicDetail.user.name}}</span>
          <div class="dot"></div>
          <span style="color: #19B3A3">Chuyên gia</span>
          <span>{{appUtils.formatDateTime(topicDetail.created_at)}}</span>
        </div>
        <div class="cursor-pointer" @click="onGoBack">
          <img src="/images/icon-close.svg" alt />
        </div>
      </div>
      <div class="mt-3">
        <div class="flex items-center justify-between mb-2">
          <p class="title">{{topicDetail.title}}</p>
          <div
            class="flex items-center flex-col bookmark cursor-pointer"
            style=" height: fit-content"
            @click="handleChangeBookmark"
          >
            <img
              v-if="!isBookmarked"
              src="/images/icon-bookmark.svg"
              alt
              style="width: 24px; height: 24px"
            />
            <img
              v-else
              src="/images/icon-bookmark-active.svg"
              alt
              style="width: 24px; height: 24px"
            />
          </div>
        </div>
        <span class="description" v-html="topicDetail.description"></span>
        <div class="mt-2">
          <el-tag
            v-for="tag in topicDetail.circle_topic_tags"
            class="flex items-center mr-2 mb-2"
            type="info"
            :key="tag.id"
          >{{ tag.circle_tag && tag.circle_tag.title }}</el-tag>
        </div>
      </div>
      <div class="flex items-center mt-2 justify-between">
        <div class="flex items-center gap-3">
          <div class="flex items-center gap-1">
            <img src="/images/icon-comment.svg" alt style="width: 20px; height: 20px" />
            <span>{{topicDetail.comment_count}} bình luận</span>
          </div>
          <div class="flex items-center gap-1">
            <img src="/images/icon-notification.svg" alt style="width: 20px; height: 20px" />
            <span>Theo dõi</span>
          </div>
        </div>
        <div class="flex items-center gap-3">
          <!-- <div class="flex items-center gap-1 cursor-pointer" @click="handleDeleteTopic">
            <img src="/images/icon-delete.svg" alt style="width: 20px; height: 20px" />
            <span class="delete">Xoá</span>
          </div>-->
          <div
            class="flex items-center gap-1 cursor-pointer"
            @click="$router.push({name: 'AddTopic', params: {type: 'edit', topic_id: topicDetail.id}})"
          >
            <img src="/images/icon-edit.svg" alt style="width: 20px; height: 20px" />
            <span class="edit">Chỉnh sửa</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
// import EditorInput from '../../../components/EditorInput.vue'
import appUtils from '@/utils/appUtils'
import { ArrowLeft } from '@/components/Common/Icons'

export default {
  name: 'TopicDetail',
  components: { ArrowLeft },
  props: {
    //
  },
  data () {
    return {
      topicDetail: {},
      appUtils,
      isBookmarked: false
    }
  },
  async mounted () {
    await this.fetchTopicDetail(this.$route.params.topic_id)
    this.isBookmarked = this.topicDetail?.is_bookmarked
  },
  methods: {
    async fetchTopicDetail (topic_id) {
      try {
        const response = await Vue.prototype.$rf
          .getRequest('QAndARequest')
          .fetchTopicByID(topic_id)
        if (response.status === 200) {
          this.topicDetail = response.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleChangeBookmark () {
      this.isBookmarked = !this.isBookmarked
      if (this.isBookmarked === true) this.addBookmark(this.topicDetail.id)
      else this.removeBookmark(this.topicDetail.id)
    },
    async addBookmark (id) {
      try {
        const response = await Vue.prototype.$rf
          .getRequest('QAndARequest')
          .addBookmark({ circle_topic_id: id })
        if (response.status === 200) {
          this.$message.success('Lưu tin thành công!')
          this.fetchTopicDetail(this.$route.params.topic_id)
        } else {
          //
        }
      } catch (error) {
        this.fetchTopicDetail(this.$route.params.topic_id)
        console.log(error)
      }
    },
    async removeBookmark (id) {
      try {
        const response = await Vue.prototype.$rf
          .getRequest('QAndARequest')
          .removeBookmark(id)
        if (response.status === 200) {
          this.$message.success('Bỏ lưu tin thành công!')
          this.fetchTopicDetail(this.$route.params.topic_id)
        } else {
        }
      } catch (error) {
        this.fetchTopicDetail(this.$route.params.topic_id)
        console.log(error)
      }
    },
    onGoBack () {
      return this.$router.go(-1)
    }
  },
  watch: {
    //
  }
}
</script>

<style lang="scss" scoped>
.back {
  color: #20409b;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.topic-content {
  border-bottom: 1px solid #ccc;
  padding-bottom: 16px;
  .name {
    color: #20409b;
  }
  .dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #20409b;
  }
  .title {
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    margin-bottom: 0;
  }
  .description {
    // white-space: normal;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // display: -webkit-box;
    // width: 100%;
    // -webkit-box-orient: vertical;
    // -webkit-line-clamp: 3;
  }

  .delete {
    &:hover {
      color: #ea6a6a;
      text-decoration: underline;
    }
  }
  .edit {
    &:hover {
      text-decoration: underline;
      color: #20409b;
    }
  }
}
</style>
